// extracted by mini-css-extract-plugin
export var boldFontWeight = "MailingList-module--bold--font-weight--3db12";
export var bounds = "MailingList-module--bounds--7f908";
export var checkbox = "MailingList-module--checkbox--06edc";
export var disclaimer = "MailingList-module--disclaimer--a41b7";
export var fieldGroup = "MailingList-module--field-group--678ad";
export var form = "MailingList-module--form--00e80";
export var input = "MailingList-module--input--e74c1";
export var label = "MailingList-module--label--359cb";
export var layout = "MailingList-module--layout--8dd21";
export var lgGap = "MailingList-module--lg--gap--0359a";
export var lineItem = "MailingList-module--line-item--9a613";
export var mdGap = "MailingList-module--md--gap--d55d7";
export var normalFontWeight = "MailingList-module--normal--font-weight--2e477";
export var row = "MailingList-module--row--655ed";
export var smGap = "MailingList-module--sm--gap--26b3e";